<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มการจัดส่ง</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อการจัดส่ง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="logisticName"
              label="ชื่อการจัดส่ง"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">หมายเหตุ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="description"
              label="หมายเหตุ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="12" md="9" sm="9" xs="9">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปโลโก้บริษัท</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row> -->
        <v-col cols="12" md="12" sm="12" xs="12">
          <h3 style="font-weight: 800; color: #e53935">เพิ่มตัวเลือก</h3>
          <v-card outlined class="px-2">
            <v-row
              v-for="(n, index) in productOptionList"
              :key="index"
              align="center"
              class="py-0 my-0"
            >
              <v-col cols="12" md="4" sm="12"
                ><span>น้ำหนักเริ่มต้น</span>
                <span style="color: red">*</span>
                <v-text-field
                  v-model="productOptionList[index].minWeight"
                  solo
                  dense
                  suffix="กก."
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12"
                ><span>ถึงน้ำหนัก</span>
                <span style="color: red">*</span>
                <v-text-field
                  v-model="productOptionList[index].maxWeight"
                  solo
                  dense
                  suffix="กก."
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12"
                ><span>ราคา</span>
                <span style="color: red">*</span>
                <v-text-field
                  v-model="productOptionList[index].price"
                  solo
                  dense
                  suffix="บาท"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" md="12" class="my-2">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="productOptionList.length > 0"
                  @click="deleteproductOptionList()"
                  dense
                  color="red"
                  class="mr-4"
                  dark
                  rounded
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn
                  @click="addproductOptionList()"
                  dense
                  color="green"
                  dark
                  rounded
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#234baa" dark >บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      logisticName: "",
      description: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      productOptionList: [],
    };
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBrand");
    },
    async addproductOptionList() {
      this.productOptionList.push({
        minWeight: this.minWeight,
        maxWeight: this.maxWeight,
        price: this.price,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userSPM"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name: this.logisticName,
          note: this.description,
          status: this.activeFlag,
          weightPrices: this.productOptionList,
          
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/shippings`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างการจัดส่งสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageLogistic");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
